<template>
  <div class="addUser">
    <el-dialog
      :title="showType ? '添加用户' : '修改用户'"
      :visible.sync="dialogVisible"
      width="490px"
      :close-on-click-modal="false"
    >
      <el-input
        v-model="keyword"
        placeholder="请输入姓名或用户名"
        @keyup.enter.native="setShowList"
      >
        <i
          slot="suffix"
          class="el-icon-search el-input__icon"
          @click="setShowList"
        >
        </i>
      </el-input>
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column prop="date" label="" align="center">
          <template slot-scope="{ row }">
            <el-radio v-model="teacherId" :label="row.teacherId">
              {{ " " }}</el-radio
            >
          </template>
        </el-table-column>
        <el-table-column prop="name" label="姓名" width="180" align="center">
        </el-table-column>
        <el-table-column
          prop="username"
          label="账号"
          width="180"
          align="center"
        >
        </el-table-column>
      </el-table>
      <el-pagination
        background
        :current-page="current"
        layout="prev, pager, next"
        :total="total"
        @current-change="currentChange"
      >
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          :loading="btnLoading"
          @click="addSchoolGroup()"
        >
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getUserList } from "@/core/api/newOther/union";
import { addSchoolGroup } from "@/core/api/exam/examCorrectTask";
export default {
  name: "AddUser",
  components: {},
  data() {
    return {
      dialogVisible: false,
      btnLoading: false,
      teacherId: "",
      keyword: "",
      total: 0,
      current: 1,
      showType: 1,
      tableData: [],
    };
  },
  created() {},
  methods: {
    addSchoolGroup() {
      if (!this.teacherId) {
        this.dialogVisible = false;
        return;
      }
      let data = { ifSetAuth: true };
      let data1 = JSON.parse(JSON.stringify(this.indexObj));
      data[this.key] = this.teacherId;
      Object.assign(data1, data);
      this.btnLoading = true;
      addSchoolGroup(data1)
        .then(() => {
          this.$message({
            showClose: true,
            message: "设置成功！",
            type: "success",
          });
          this.dialogVisible = false;
          this.btnLoading = false;
          this.$parent.getList();
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    init(row, type, key) {
      this.dialogVisible = true;
      this.keyword = "";
      this.setShowList();
      this.indexObj = JSON.parse(JSON.stringify(row));
      this.teacherId = row[key];
      this.showType = type;
      this.key = key;
    },
    currentChange(val) {
      this.current = val;
      this.setShowList();
    },
    setShowList() {
      let data = {
        keyword: this.keyword,
        current: this.current,
        size: 10,
      };
      getUserList(data).then((res) => {
        this.tableData = res.data.data.records;
        this.total = res.data.data.total;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.addUser {
  .el-input {
    width: 180px;
    margin-bottom: 18px;
  }
  ::v-deep .el-pagination {
    margin-right: -10px;
    .number {
      padding: 0;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      background-color: #ffffff;
    }
    button {
      padding: 0;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.15);
      background-color: #ffffff;
    }
    .btn-quicknext,
    .btn-quickprev {
      background-color: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.15);
    }
    .el-pager li:not(.disabled).active {
      background-color: #ffffff;
      border-color: #2474ed;
      color: #2474ed;
    }
  }
}
</style>
