<template>
  <div class="importFile">
    <el-dialog
      title="导入"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="550px"
    >
      <ElImportUpload
        ref="ElImportUpload"
        @upLoadSucc="upLoadSucc"
        @upLoadLoading="upLoadLoading"
      >
        <template slot="tips">
          <div class="tips">
            <div class="tips-title">【提示】</div>
            1、分组名称不得重复，名称不超过十个字符；<br />
            2、所有学校务必全部分组，若未能进行分组的学校，系统将自动归为一组；<br />
            3、不同组内包含的学校不能为空或者重复、学校名称需要和系统内的学校一致、若组内存在多个学校名称请用,分隔；<br />
            4、导入文件中，只要存在一条数据不合法，则所有数据都无法导入；
          </div>
        </template>
        <template slot="down">
          <div class="down-box">
            <div>
              <img src="@/assets/uExam/icon-down.png" alt="" />
              下载模版：
              <span class="down-tips" @click="downFile()"> 分组模版.xlsx </span>
            </div>
          </div>
        </template>
      </ElImportUpload>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submit()"
          >导 入</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import ElImportUpload from "@/components/ElImportUpload";
import { fileDownloadByUrl } from "@/core/util/util";
export default {
  name: "ImportFile",
  components: { ElImportUpload },
  data() {
    return {
      dialogVisible: false,
      btnLoading: false,
      btnType: 0,
    };
  },
  created() {},
  methods: {
    //

    downFile() {
      let url =
        "https://static.wtjy.com/exam/template/%E5%88%86%E7%BB%84%E9%98%85%E5%8D%B7%E5%AF%BC%E5%85%A5.xls";
      let name = "分组模版.xlsx";
      fileDownloadByUrl(url, name);
    },
    submit() {
      this.btnLoading = true;
      this.$refs.ElImportUpload.submit();
    },
    upLoadLoading() {
      this.btnLoading = false;
    },
    upLoadSucc(data) {
      this.data = data;

      setTimeout(() => {
        this.$parent.getList();
      }, 2000);
    },
    init() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        let url = "/exam/exammark/group/import";
        let accept = ".xlsx,.xls";
        this.$refs.ElImportUpload.init(url, accept);
        this.$refs.ElImportUpload.queryData = {
          examId: this.$route.query.examId,
          examPaperId: this.$route.query.paperId,
        };
      });
    },
  },
};
</script>
<style scoped lang="scss">
.importFile {
}
</style>
