<template>
  <div class="ExamTaskGroup">
    <detailsHeader ref="detailsHeader"></detailsHeader>
    <div class="task-box">
      <div class="task-header">
        <span>设置阅卷分组</span>
        <div>
          <el-button type="primary" @click="addGroupFn()">
            <img src="@/assets/uExam/icon_add_w.png" alt="" />
            <img class="on" src="@/assets/uExam/icon_add_w.png" alt="" />
            新增分组
          </el-button>
          <!-- <el-button type="primary" @click="importUser()">
            <img src="@/assets/uExam/icon_import.png" alt="" />
            <img class="on" src="@/assets/uExam/icon_import.png" alt="" />
            导入
          </el-button> -->
        </div>
      </div>
      <div class="task-tips">
        <p>
          <img src="@/assets/uExam/icon_waring.png" alt="" />
          注意：1、所有学校务必全部分组，未能进行分组学校系统将自动归为一组；<br />
        </p>
        <p class="">2、进度监控、任务分配教师仅支持设置一名教师；</p>
      </div>
      <el-table
        v-if="examInformation.id"
        ref="multipleTable"
        border
        :data="dataList"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <!-- <el-table-column
          label="学段"
          width="180"
          prop="studentNo"
          align="center"
        >
        </el-table-column> -->
        <el-table-column
          label="分组名称"
          width="180"
          prop="name"
          align="center"
        >
        </el-table-column>
        <el-table-column
          width="565"
          label="学校"
          prop="schoolNames"
          align="center"
        >
        </el-table-column>
        <el-table-column label="进度监控" prop="studentNo" align="center">
          <template slot-scope="{ row }">
            <div class="user-box">
              <div v-if="row.processMonitorId" class="user-list">
                <div class="user-list-box">
                  <div class="name">{{ row.processMonitorName }}</div>
                  <div>{{ row.processMonitorUsername }}</div>
                </div>
              </div>
              <div class="btn-icon" @click="addUser(row, 'processMonitorId')">
                <img
                  v-if="!row.processMonitorId"
                  src="@/assets/uExam/icon_add.png"
                  alt=""
                />
                <img v-else src="@/assets/uExam/icon_edit.png" alt="" />
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="任务分配" prop="studentNo" align="center">
          <template slot-scope="{ row }">
            <div class="user-box">
              <div v-if="row.taskAllocateId" class="user-list">
                <div class="user-list-box">
                  <div class="name">{{ row.taskAllocateName }}</div>
                  <div>{{ row.taskAllocateUsername }}</div>
                </div>
              </div>
              <div class="btn-icon" @click="addUser(row, 'taskAllocateId')">
                <img
                  v-if="!row.taskAllocateId"
                  src="@/assets/uExam/icon_add.png"
                  alt=""
                />
                <img v-else src="@/assets/uExam/icon_edit.png" alt="" />
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="140" align="center">
          <template slot-scope="{ row }">
            <el-button type="text" @click="showEdit(row)"> 修改 </el-button>
            <el-button type="text" style="color: #ff0000" @click="delItem(row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="btn-box">
        <el-button type="primary" @click="submitList()">保存</el-button>
      </div>
    </div>
    <addGroup ref="addGroup"></addGroup>
    <importFile ref="importFile"></importFile>
    <addUser ref="addUser"></addUser>
  </div>
</template>

<script>
import detailsHeader from "@/components/detailsHeader.vue";
import addGroup from "./components/addGroup.vue";
import importFile from "./components/importFile.vue";
import addUser from "./components/addUser.vue";
import { getObj, schoollistbyexamid } from "@/core/api/exam/exam";
import {
  schoolGroupList,
  deleteGroup,
  addSchoolGroup,
} from "@/core/api/exam/examCorrectTask";
export default {
  name: "ExamTaskGroup",
  components: { detailsHeader, addGroup, importFile, addUser },
  data() {
    return {
      dataList: [],
      schoolList: [],
      examInformation: {
        name: "",
      },
    };
  },
  async created() {
    this.getExamInfo();
    this.getExamSchools();
    this.getList();
  },
  methods: {
    submitList() {
      let arr = [];
      this.dataList.map((item) => {
        let splitArr = item.schoolIds.split(",").map((item) => Number(item));
        arr = arr.concat(splitArr);
      });
      if (arr.length == this.schoolList.length) {
        this.$message({
          showClose: true,
          message: "保存成功!",
          type: "success",
        });
        return;
      }
      let schoolList = this.schoolList.filter(
        (item) => arr.indexOf(item.schoolId) == -1
      );
      this.showAdd(schoolList);
    },
    showAdd(schoolList) {
      const h = this.$createElement;
      this.$msgbox({
        title: "提示",
        message: h("p", null, [
          h(
            "span",
            null,
            `还有${schoolList.length}所学校未分组，系统将自动归为“其他”组，是否继续保存? `
          ),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            this.addSchoolGroup(schoolList, instance, done);
          } else {
            done();
          }
        },
      }).then(() => {});
    },
    addSchoolGroup(schoolList, instance, done) {
      let arr = schoolList.map((item) => item.schoolId);
      let data = {
        schoolIds: arr.join(","),
        examId: this.$route.query.examId,
        examPaperId: this.$route.query.paperId,
        id: "",
        name: "其他",
      };

      this.btnLoading = true;
      addSchoolGroup(data)
        .then(() => {
          this.$message({
            showClose: true,
            message: "保存成功！",
            type: "success",
          });
          instance.confirmButtonLoading = false;
          this.getList(1);
          done();
        })
        .catch(() => {
          this.btnLoading = false;
          instance.confirmButtonLoading = false;
        });
    },
    async getExamSchools() {
      const res = await schoollistbyexamid({
        examId: this.$route.query.examId,
      });
      this.schoolList = res.data.data;
    },
    delItem(row) {
      const h = this.$createElement;
      this.$msgbox({
        title: "消息",
        message: h("p", null, [
          h("span", null, "当前正在删除 "),
          h("span", { style: "color: red" }, row.name),
          h("span", null, " 删除后不可恢复 "),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            let data = {
              id: row.id,
            };
            this.deleteGroup(data, instance, done);
          } else {
            done();
          }
        },
      }).then(() => {});
    },
    deleteGroup(data, instance, done) {
      deleteGroup(data)
        .then(() => {
          this.$message({
            showClose: true,
            message: "删除成功！",
            type: "success",
          });
          instance.confirmButtonLoading = false;
          done();
          this.getList();
        })
        .catch(() => {
          instance.confirmButtonLoading = false;
        });
    },
    addGroupFn() {
      this.$refs.addGroup.init();
    },
    showEdit(row) {
      this.$refs.addGroup.init(row);
    },
    addUser(row, key) {
      let type = row[key];
      if (type) {
        type = 1;
      } else {
        type = 2;
      }
      this.$refs.addUser.init(row, type, key);
    },
    importUser() {
      this.$refs.importFile.init();
    },
    //获取考试基本信息
    getExamInfo() {
      getObj(this.$route.query.examId).then((res) => {
        this.examInformation = res.data.data;
        this.paperList = res.data.data.paperList;
      });
    },
    // 弹出阅卷模式
    getShowTypes() {
      let arr = [];
      this.dataList.map((item) => {
        let splitArr = item.schoolIds.split(",").map((item) => Number(item));
        arr = arr.concat(splitArr);
      });

      let schoolList = this.schoolList.filter(
        (item) => arr.indexOf(item.schoolId) == -1
      );
      if (schoolList.length == 0) {
        const h = this.$createElement;
        this.$msgbox({
          title: "提示",
          customClass: "model-tips",
          message: h("p", null, [
            h("p", null, `学校已分组完毕，请及时设置抽卷方式！`),
            h("p", null, `抽卷模式介绍：`),
            h("p", null, `组内抽卷：各阅卷组可自由批阅本组学校考生试卷`),
            h(
              "p",
              null,
              `全局抽卷：各阅卷组可按照设定任务数批阅所有参考学校考生试卷`
            ),
          ]),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {})
          .catch(() => {});
      }
    },
    //联考-获取学校分组列表
    getList(type) {
      let data = {
        examId: this.$route.query.examId,
        examPaperId: this.$route.query.paperId,
      };
      schoolGroupList(data).then((res) => {
        this.dataList = res.data.data;
        if (type) {
          this.getShowTypes();
        }
      });
    },
  },
};
</script>
<style>
.model-tips {
  width: 540px;
}
</style>
<style scoped lang="scss">
.ExamTaskGroup {
  padding-top: 18px;

  .btn-box {
    text-align: center;
    padding-top: 18px;
  }
  .btn-icon {
    width: 36px;
    height: 32px;
    background-color: #f2f6ff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-shrink: 0;
    margin: 4px 0;
    img {
      width: 16px;
    }
  }
  ::v-deep
    .el-table--enable-row-hover
    .el-table__body
    tr:hover
    > td.el-table__cell {
    background-color: #ffffff;
  }
  .user-box {
    display: flex;
    // flex-wrap: wrap;
    align-items: flex-start;
    // justify-content:;
    .user-list {
      display: flex;
      flex-wrap: wrap;

      .user-list-box {
        display: flex;
        border-radius: 4px;
        border: 1px solid #f2f6ff;
        margin: 4px 6px;
        .name {
          background-color: #f5f7fa;
        }
        > div {
          padding: 4.5px 8px;
        }
      }
    }
  }
  ::v-deep {
    .tab-box .el-table .el-table__cell {
      padding: 2px 0;
    }
  }
  .task-tips {
    padding: 13px 18px;
    color: #292626;
    background: #fff3f3;
    border-radius: 4px;

    line-height: 14px;
    margin: 18px 0;
    p {
      width: 100%;
      display: flex;
      align-items: center;
      &:last-child {
        padding-left: 69px;
      }
    }
    img {
      margin-right: 8px;
    }
  }
  .task-box {
    padding: 18px;
    background-color: #ffffff;
    margin-top: 18px;
    .task-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      > span {
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}
</style>
